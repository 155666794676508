import React, { useState } from "react";
import { useEffect } from "react";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { useSelector } from "react-redux";
import BreadcrumbNav from "./BreadcrumbNav";
import { getpagesApi } from "../store/actions/campaign";
import { translate } from "../utils"

const Privacy = () => {
    const [Data, setData] = useState([]);
    const currentLanguage = useSelector(selectCurrentLanguage);
    const [privacyPolicy, setPrivacyPolicy] = useState();
    useEffect(() => {
        getpagesApi(
            (response) => {
                let allData = response.data;
                setData(allData);
                
            },
            (error) => {
                setData("");
                console.log(error);
            }
        );
    }, [currentLanguage]);
    useEffect(() => {
        const privacyPolicydata = Data.find(item => item.id === '4');
        setPrivacyPolicy(privacyPolicydata);
        console.log('abc privacy', privacyPolicy);
    }, [Data, privacyPolicy]); 
    
    return (
        <>
            <BreadcrumbNav SecondElement={translate("Privacy")} ThirdElement="0" />
            <div className="morepages py-5 bg-white">
                <div className="container">
                    <div className="row">
                    <div className="col-md-12 col-12 mb-4">
                        
                    <p className="p-3 mb-0" dangerouslySetInnerHTML={{ __html: privacyPolicy && privacyPolicy.page_content }}></p>

                    </div>
                    </div>
                </div>
            </div>

            
        </>
    );
}

export default Privacy;
